
import { Component, Prop, Vue } from "vue-property-decorator";
import { HousfyPropertyCardShimmer } from "housfy-ui-lib";

@Component({
  name: "OwnerLeadsShimmer",
  components: {
    HousfyPropertyCardShimmer,
  },
})
export default class OwnerLeadsShimmer extends Vue {
  @Prop({ type: Number, default: 1 })
  cardsDisplayed?: number;
}
